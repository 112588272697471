.projects {
    border: 1px solid rgba(0, 0, 0, 0.15);
    
    .title {
        padding: 2.5rem 6rem;
        font-size: 1.4rem;
        font-weight: 500;
    }

    .proj-list {
        display: flex;
        width: 100%;
        flex-wrap: wrap;

        .project {
            flex: 1 1 600px;
            height: 434px;
            padding: 2rem;
            background-size: cover;
            color: #fff;
            position: relative;
            cursor: pointer;
            overflow-y: hidden;

            .overlay {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: rgba(0, 0, 0, 1);
                padding: 2rem;
                transform: translateY(-500%);


                .name {
                    margin-bottom: .6rem;
                    font-weight: 500;
                    font-size: 1.3rem;
                }
            }

            &:hover {
                .overlay {
                    animation: slideIn 250ms linear forwards;
                }
            }

            .name {
                margin-bottom: .6rem;
                font-weight: 500;
                font-size: 1.3rem;
            }
        }
    }
}

@media screen and (max-width: 690px) {
    .projects {
        .title {
            padding: 2.5rem 1rem;
        }
    }
}

@keyframes slideIn {
    from {
        transform: translateY(-500%);
    }
    to {
        transform: translateY(0);
    }
}