.side-bar {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 30;
    .wrapper {
        background-color: rgba(0, 0, 0, .8);
        position: relative;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        .side {
            width: 80%;
            background-color: #fff;
            position: absolute;
            height: 100vh;
            right: 0;
            padding: .5rem;

            .linksx {
                transition: 250ms ease;
        
                li {
                    list-style: none;
                    color: #000;
                    margin:  2rem 1rem;
                    position: relative;
                    padding-bottom: 2px;
                    width: fit-content;
                    // margin-block: 2px;
        
                    &::after {
                        content: "";
                        width: 100%;
                        height: 1px;
                        background-color: #000;
                        border-radius: 4px;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        transform: scaleX(0);
                        transform-origin: left;
                        transition: transform .25s ease;
                    }
        
                    &:hover::after {
                        transform: scaleX(1);
                    }
                }
                
        
            }
        }
    }
}