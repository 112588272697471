.navbar2 {
    display: flex;
    padding: 1rem;
    align-items: center;
    justify-content: space-between;
    // position: fixed;
    // z-index: 100;
    // background-color: red;
    // width: 100%;

    img {
        cursor: pointer;
        padding-left: 10px;
    }

    .links {
        display: flex;
        transition: 250ms ease;

        .active {
            // border-bottom: 1px solid black;
            span {
                display: block;
            }
        }

        

        li {
            list-style: none;
            color: #000;
            font-weight: 500;
            margin: 0 1rem;
            position: relative;
            padding: 0 0 2px 0 !important;
            margin-block: 2px;

            &::after {
                content: "";
                width: 100%;
                height: 1.5px;
                background-color: rgb(72, 72, 72);
                border-radius: 4px;
                position: absolute;
                left: 0;
                bottom: 0;
                transform: scaleX(0);
                transform-origin: left;
                transition: transform .25s ease;
            }

            &:hover::after {
                transform: scaleX(1);
            }

            span {
                position: absolute;
                font-size: 3rem;
                border-radius: 50%;
                top: -215%;
                left: 35%;
                display: none;
                transition: 250ms ease;
            }
        }
        

    }
}

@media screen and (max-width: 900px) {
    .navbar2 {
        .links {
            display: none;
        }
    }
}