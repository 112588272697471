@media screen and (max-width: 414px) and (orientation: landscape) {
    html {
      transform: rotate(-90deg);
      transform-origin: left top;
      width: 100vh;
      overflow-x: hidden;
      position: absolute;
      top: 100%;
      left: 0;
    }
  }

.homepage {
    position: relative;
    width: 100vw;
    overflow-x: hidden;

    .video-holder {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        display: flex;
        align-items: center;

        @media screen and (min-width: 415px) {
            .video-desktop{
                display: block;
            }
            .video-phone{
                display: none;
            }
        }

        @media screen and (max-width: 414px) {
            .video-desktop{
                display: none;
            }
            .video-phone{
                display: block;
            }
        }

        

        video {
            width: 100%;
            position: relative;
            // height: 100vh;
            // .para {
            //     color: red;
            //     z-index: 20;
            // }
    
            
        }
    }

    
    .arrow {
        text-align: center;

        img {
            width: .7%;
            position: absolute;
            cursor: pointer;
            margin-top: -3.5rem;
            transform: scale(3.6);
            animation-name: bounce-2;
            animation-timing-function: ease;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            // transform-origin: bottom;
        }
    }
    
    iframe {
        width: 100%;
        height: 100vh;
    }
    .paray {
        width: 581px;
        z-index: 10;
        position: absolute;
        color: #fff;
        font-size: 21px;
        line-height: 35px;
        top: 5%;
        left: 5%;
        // margin-top: -2rem;
    }

    .hero {
        height: 100vh;
        background-color: gray;
        // background-image: url(./bg.svg);
        background-size: cover;

        .cont {
            display: flex;
            // padding: 0 6rem;
            background-color: gray;
            height: 100%;
            // align-items: center;
            position: relative;

            video {
                height: 100%;
                width: 100%;
                // position: absolute;
                // top: 0;
                // bottom: 0;
                // left: 0;
                // right: 0;
                // height: 100vh;
                // width: 100vw;
            }
    
            .para {
                width: 581px;
                z-index: 10;
                position: absolute;
                color: #fff;
                font-size: 21px;
                line-height: 35px;
                // margin-top: -2rem;
            }
        }
    }

    .info {
        padding: 4rem 6rem;

        .para {
            font-size: 21px;
            line-height: 35px;
            width: 600px;
            font-weight: 500;
        }
        .more {
            font-weight: 500;
            cursor: pointer;
            position: relative;
            width: max-content;

            &::after {
                content: "";
                width: 100%;
                height: 1px;
                background-color: #000;
                border-radius: 4px;
                position: absolute;
                left: 0;
                bottom: 0;
                transform: scaleX(0);
                transform-origin: left;
                transition: transform .25s ease;
            }

            &:hover::after {
                transform: scaleX(1);
            }
        }
    }

    .title {
        padding: 2.5rem 6rem;
        font-size: 1.4rem;
        font-weight: 500;
    }

    .experh {
        background-color: #000;
        color: #fff;
        padding: 6rem 6rem 5.3rem 6rem;
        display: flex;
        gap: 2rem 6rem;
        font-size: 21px;

        
        .conth {
            
            .toph {
                margin-bottom: 1.5rem;
            }
            .parah {
                width: 840px;
                line-height: 1.5;
                margin-bottom: 2rem;
                
            }
            .more {
                font-size: 1rem;
                cursor: pointer;
                position: relative;
                width: max-content;

                &::after {
                    content: "";
                    width: 100%;
                    height: 1.5px;
                    background-color: rgb(255, 255, 255);
                    border-radius: 4px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    transform: scaleX(0);
                    transform-origin: left;
                    transition: transform .25s ease;
                }
    
                &:hover::after {
                    transform: scaleX(1);
                }
            }
        }
    }
    .banner {
        height: 774px;
        background-size: cover;
    }
    .ban {
        height: 774px;
        background-color: gray;
        background-size: cover;
        background-image: url(https://res.cloudinary.com/giftguy/image/upload/v1655561130/q1_xv8ofg.png);
        // width: 100%;
    }
    .about {
        color: #fff;
        background-color: #000;
        padding: 4rem 6rem;
        font-weight: 500;
        border-bottom: 1px solid rgba(202, 202, 202, .7);

        .titlem {
            margin-bottom: 1.5rem;
            font-size: 21px;
        }

        .cont {
            font-size: 21px;
            .top {
                margin-bottom: 1.5rem;
            }
            .para {
                width: 800px;
                line-height: 1.5;
                margin-bottom: 2rem;
                
            }
            .more {
                font-size: 1rem;
                cursor: pointer;
                position: relative;
                width: max-content;

            &::after {
                content: "";
                width: 100%;
                height: 1px;
                background-color: #000;
                border-radius: 4px;
                position: absolute;
                left: 0;
                bottom: 0;
                transform: scaleX(0);
                transform-origin: left;
                transition: transform .25s ease;
            }

            &:hover::after {
                transform: scaleX(1);
            }
            }
        }
    }

}

@media screen and (max-width: 690px) {
    .homepage {
        .info {
            padding: 1rem;
            width: 100%;

            .para {
                width: 100%;
                font-size: 18px;
            }
            .more {
                font-size: 14px;
            }
        }
        .title {
            padding: 2.5rem 1rem;
        }
        .experh {
            padding: 3rem 1rem;
            font-size: 18px;
            flex-wrap: wrap;

            .conth {
                .toph {
                    font-size: 18px;
                    width: fit-content;
                }
                .parah {
                    font-size: 18px;
                    width: 100%;
                }
                .more {
                    font-size: 14px;
                }
            }
        }
        .ban {
            height: 500px;
        }
        .about {
            padding: 4rem 1rem 2rem 1rem;

            .titlem {
                font-size: 18px;
            }
            .cont {
                font-size: 18px;

                .para {
                    width: 100%;
                }
            }
        }
    }
}

@keyframes bounce-2 {
    0%   { transform: translateY(0) scale(3.6); }
    50%  { transform: translateY(-20px) scale(3.6); }
    100% { transform: translateY(0) scale(3.6); }
}