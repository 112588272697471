.about {
    
    .sec1 {
        color: #000;
        padding: 4rem;
        margin: 2rem 0;
        font-weight: 500;
        font-size: 21px;
        border-top: 1px solid rgba(0, 0, 0, 0.2);

        .title {
            margin-bottom: 1.5rem;
        }

        .cont {
            .top {
                margin-bottom: 1.5rem;
            }
            .para {
                width: 800px;
                line-height: 1.5;
                margin-bottom: 2rem;
                
            }
            .more {
                font-size: .9rem;
                cursor: pointer;
            }
        }
    }
    .nx {
        border-top: none;
    }
    .sec3 {
        color: #000;
        padding: 2rem 4rem;
        margin: 2rem 0;
        font-weight: 500;

        .title {
            margin-bottom: 1.5rem;
        }

        .cont {
            .top {
                margin-bottom: 1.5rem;
            }
            .para {
                width: 600px;
                line-height: 1.5;
                margin-bottom: 2rem;
                
            }
            .more {
                font-size: .9rem;
                cursor: pointer;
            }
        }
    }
    .sec2 {
        color: #000;
        padding: 4rem;
        font-weight: 500;
        display: flex;
        font-size: 21px;
        justify-content: flex-end;

        .wrap {

            .title {
                margin-bottom: 1.5rem;
            }
    
            .cont {
                .top {
                    margin-bottom: 1rem;
                }
                .para {
                    width: 760px;
                    line-height: 1.5;
                    margin-bottom: 1rem;
                    
                }
                .more {
                    font-size: .9rem;
                    cursor: pointer;
                }
            }
        }
        
    }
    .bg1 {
        height: 780px;
        background-color: gray;
        background-image: url(https://res.cloudinary.com/giftguy/image/upload/v1655566649/a1_lt04ui.png);
        background-size: cover;
        // width: 100%;
    }
    .bg2-wrap {
        padding: 0 4rem;
        .bg2 {
            height: 780px;
            background-color: gray;
            background-image: url(https://res.cloudinary.com/giftguy/image/upload/v1655566649/a2_l0rmxk.png);
            background-size: cover;
        }
    }
    .bg3-wrap {
        padding: 0 4rem;
        .bg3 {
            height: 780px;
            background-color: gray;
            background-image: url(https://res.cloudinary.com/giftguy/image/upload/v1655566649/a3_su23v2.png);
            background-size: cover;
        }
    }
    .last {
        padding: 5rem 6rem 1rem 6rem;

        .titlec {
            color: #000;
            font-weight: 500;
            font-size: 21px;
            margin-bottom: 2rem;
        }

        .inner {
            height: 600px;
            background-image: url(https://res.cloudinary.com/giftguy/image/upload/v1655566783/clients_dot5gt.svg);
            background-size: contain;
            background-repeat: no-repeat;
        }
        
    }
    
}

@media screen and (max-width: 1100px) {
    .about {
        .sec1 {
            padding: 2rem 1rem;
            font-size: 18px;

            .cont {
                .para {
                    width: 100%;
                }
            }
        }
        .sec2 {
            font-size: 18px;
            padding: 2rem 1rem;

            .wrap {
                .cont {
                    .para {
                        width: 100%;
                    }
                }
            }
        }
        .bg1 {
            height: 500px;
            // height: auto;
        }
        .bg2-wrap {
            padding: 0 1rem;

            .bg2 {
                height: 500px;
            }
        }
        .bg3-wrap {
            padding: 0 1rem;

            .bg3 {
                height: 500px;
            }
        }
    }
}