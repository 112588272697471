.expertise {

    .nav {
        display: flex;
        margin-top: 1rem;
        margin-bottom: .5rem;
        list-style: none;
        gap: 1.5rem;
        padding: .5rem 1.7rem;

        li {
            color: rgba(41, 41, 41, 0.4);
            font-weight: 600;
            cursor: pointer;
            white-space: nowrap;
            transition: 250ms ease;
        }
        .active {
            color: #292929;
        }
    }
}


@media screen and (max-width: 1100px) {
    .expertise {
        .nav {
            gap: 1rem;
            overflow-x: scroll;
            padding: .5rem 1rem;

            &::-webkit-scrollbar {
                display: none;
            }

            li {
                font-size: 14px;
            }
        }
    }
}