.career {
    .sec1 {
        color: #000;
        padding: 4rem;
        margin: 2rem 0 3rem 0;
        font-weight: 500;
        font-size: 21px;
        border-top: 1px solid rgba(0, 0, 0, 0.2);

        .title {
            margin-bottom: 1.5rem;
        }

        .cont {
            .top {
                margin-bottom: 1.5rem;
            }
            .para {
                width: 800px;
                line-height: 1.5;
                margin-bottom: 2rem;
                
            }
            .more {
                font-size: .9rem;
                cursor: pointer;
            }
        }

        
    }
    .bg {
        height: 578px;
        // width: 100%;
        background-color: gray;
        background-image: url(https://res.cloudinary.com/giftguy/image/upload/v1655573203/c_wozbbm.png);
        background-size: cover;
        background-position: center;
    }
    .sec2 {
        color: #000;
        padding: 4rem;
        margin: 1rem 0;
        font-weight: 500;
        font-size: 21px;

        .title {
            margin-bottom: 1.5rem;
        }

        .cont {
            .top {
                margin-bottom: 1.5rem;
            }
            .para {
                width: 800px;
                line-height: 1.5;
                margin-bottom: 2rem;
                
            }
            .more {
                font-size: .9rem;
                cursor: pointer;
            }
        }

        
    }

    .jobs {
        padding: 0 4rem 2rem 4rem;

        .job {
            margin-bottom: 3rem;
            .header {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 1.2rem;
                margin-bottom: 2rem;
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                transition: 250ms ease;

                &:hover {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.7);
                }
    
                p {
                    font-size: 17px;
                }

                img {
                    transition: 250ms ease;
                    width: 1.5%;
                }
                #times {
                    transform: rotate(45deg);
                }

                // .tr {
                //     background-color: red;
                // }
            }
            .body {
                width: 746px;
                font-size: 17px;
                animation: slideIn 250ms linear 1;

                .imp {
                    font-weight: 600;
                    margin-top: 1.5rem;
                }

                .desc {
                    line-height: 25px;
                }
                .info {
                    margin-top: 1.5rem;
                }
                .res {
                    margin-top: 1.5rem;
                    .title {
                        text-decoration: underline;
                    }
                    .ress {
                        margin-top: 1.2rem;
                        margin-left: 1.2rem;
                        line-height: 25px;
                    }
                }
            }
        }
    }
}

@keyframes slideIn {
    from {
       transform: translateY(-30px);
       opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@media screen and (max-width: 1100px) {
    .career {
        .sec1 {
            padding: 2rem 1rem;
            font-size: 18px;
            margin: 1rem 0 2rem 0;

            .cont {
                .para {
                    width: 100%;
                }
            }
        }
        .sec2 {
            padding: 2rem 1rem;
            font-size: 18px;

            .cont {
                .para {
                    width: 100%;
                }
            }
        }
        .bg {
            height: 400px;
        }
        .jobs {
            padding: 0 1rem 2rem 1rem;

            .job {
                .header {
                    img {
                        width: 3.5%;
                    }
                }
                .body {
                    width: 100%;
                }
            }

        }
    }
}