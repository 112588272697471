.media, .projects {
    .proj-list {
        display: flex;
        width: 100%;
        flex-wrap: wrap;

        .project {
            flex: 1 1 500px;
            height: 434px;
            padding: 2rem;
            background-size: cover;
            color: #fff;
            position: relative;
            cursor: pointer;

            .overlay {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: rgba(0, 0, 0, 1);
                padding: 2rem;
                transform: translateY(-500%);


                .name {
                    margin-bottom: .6rem;
                    font-weight: 500;
                    font-size: 1.3rem;
                }

                
            }

            .name {
                margin-bottom: .6rem;
                font-weight: 500;
                font-size: 1.3rem;
            }

            &:hover {
                .overlay {
                    // animation: slideIn 250ms linear forwards;
                }
            }
        }
    }
}

@media screen and (max-width: 1100px) {
    .media {
        .proj-list {
            .project {
                .name {
                    font-size: 1.1rem;
                }
            }
        }
    }
}

// @keyframes slideIn {
//     from {
//         transform: translateY(-500%);
//     }
//     to {
//         transform: translateY(0);
//     }
// }