.template {
    padding: 0 !important;
    .bg {
        height: 578px;
        background-color: gray;
        background-size: cover;
        background-position: center;
        // width: 100%;
    }
    .black {
        background-color: #000;
        color: #fff;
        padding: 5.3rem 4rem 7rem 4rem;
        box-sizing: border-box;
        display: flex;
        align-items: baseline;
        gap: 10rem;

        .title {
            width: 254px;
            font-size: 17px;
        }
        

        
        .cont {
            
            .top {
                margin-bottom: 2.5rem;
                font-size: 21px;
                width: 760px;
                line-height: 35px;
            }
            .para {
                width: 840px;
                line-height: 1.5;
                margin-bottom: 2rem;
                font-size: 17px;
                width: 760px;
            }
            .more {
                font-size: 1rem;
                cursor: pointer;
                position: relative;
                width: fit-content;

                &::after {
                    content: "";
                    width: 100%;
                    height: 1.5px;
                    background-color: rgb(255, 255, 255);
                    border-radius: 4px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    transform: scaleX(0);
                    transform-origin: left;
                    transition: transform .25s ease;
                }
    
                &:hover::after {
                    transform: scaleX(1);
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .template {
        .bg {
            height: 400px;
        }
        .black {
            padding: 3rem 1rem 2rem 1rem;
            gap: 4rem;
            flex-direction: column;

            .title {
                width: 100%;
                font-size: 16px;
            }
            .cont {
                .top {
                    font-size: 18px;
                    width: 100%;
                    line-height: 1.7rem;
                }
                .para {
                    font-size: 16px;
                    width: 100%
                }
            }
        }
    }
}