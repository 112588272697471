.view-media {
    .timg {
        height: 100vh;
        // width: 100%;
        background-image: gray;
        background-size: cover;
        background-position: center;
    }

    .bottomm {
        padding: 6rem;

        .desc {
    
            .date {
                font-weight: 900;
                font-size: 17px;
                margin-bottom: 1rem;
            }
            .titlem {
                font-size: 21px;
                font-weight: 600;
                margin-bottom: 1.5rem;
            }
            .parad {
                width: 680px;
                line-height: 26px;
                font-weight: 500;
                padding-bottom: 1rem;
            }
            .descimgt {
                // height: 700px;
                width: 100%;
                // background-color: gray;
                // background-size: cover;
                margin-top: 3rem;
            }
        }

        .secm {
            padding-top: 6rem;
            box-sizing: border-box;
            
            .flexy {
                display: flex;
                
                .point {
                    font-size: 17px;
                    line-height: 26px;
                    margin: .8rem 0;

                    .boldm {
                        font-weight: 700;
                    }
                    .normal {
                        width: 700px;
                    }
                }
            }
            .flex1 {
                justify-content: flex-end;
            }
            .flex2 {
                justify-content: flex-start;
            }

            .imgx {
                // height: 700px;
                // background-color: gray;
                // background-size: cover;
                width: 100%;
                margin-top: 6rem;
            }
        }
    }
    
}

@media screen and (max-width: 1100px) {
    .view-media {
        .bottomm {
            padding: 2rem 1rem;

            .desc {
                .date {
                    font-size: 15px;
                }
                .titlem {
                    font-size: 18px;
                }
                .parad {
                    width: 100%;
                }
                .descimgt {
                    height: auto;
                }
            }
            .secm {
                padding-top: 2rem;
                .flexy {
                    .point {
                        .normal {
                            width: 100%;
                        }
                    }
                }
                .imgx {
                    margin-top: 3rem;
                }
            }
        }
    }
}