.client {
    .last {
        padding: 5rem 6rem 1rem 6rem;

        .titlec {
            color: #000;
            font-weight: 500;
            font-size: 24px;
            margin-bottom: 2rem;
        }

        .inner {
            // height: 600px;
            background-image: url(https://res.cloudinary.com/giftguy/image/upload/v1655566783/clients_dot5gt.svg);
            background-size: contain;
            background-repeat: no-repeat;
        }
        
    }
}

@media screen and (max-width: 1100px) {
    .client {
        .last {
            padding: 3rem 1rem 2rem 1rem;
        }
        .inner {
            height: 200px !important;
        }
    }
}