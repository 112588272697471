// .containerv {
// 	width: 100%;
// 	height: 100vh;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// }

.hamburgerb {
    display: none;
    transition: 250ms ease;
}

$stroke-color: #000;

#hamburgerb {
	background-color: transparent;
	border: none;
	cursor: pointer;
	display: flex;
	padding: 0;
    position: absolute;
    transform: scale(.5) translateY(-15px);
    top: 0;
    right: 0;
	z-index: 31;

	.line {
		fill: none;
		stroke: $stroke-color;
		stroke-width: 4;
		transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
			stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);

		&1 {
			stroke-dasharray: 60 207;
			stroke-width: 4;
		}
		&2 {
			stroke-dasharray: 60 60;
			stroke-width: 4;
		}
		&3 {
			stroke-dasharray: 60 207;
			stroke-width: 4;
		}
	}
	&.open .line {
		&1 {
			stroke-dasharray: 90 207;
			stroke-dashoffset: -134;
			stroke-width: 4;
		}
		&2 {
			stroke-dasharray: 1 60;
			stroke-dashoffset: -30;
			stroke-width: 4;
		}
		&3 {
			stroke-dasharray: 90 207;
			stroke-dashoffset: -134;
			stroke-width: 4;
		}
	}
}

@media screen and (max-width: 900px) {
    .hamburgerb {
        display: block;
    }
}