.footer {
    background-color: #000;
    color: #fff;
    padding: 6rem 6rem 1rem 6rem;
    display: flex;
    flex-wrap: wrap;
    column-gap: 4rem;
    row-gap: 4rem;
    box-sizing: border-box;
    justify-content: space-between;
    position: relative;

    .chev {
        position: absolute;
        right: 2%;
        top: 6%;
        cursor: pointer;
        transform: scale(.8);
        z-index: 30;
    }

    .left {
        .top {
            margin-bottom: 3rem;
            .name {
                font-weight: 500;
                font-size: 18px;
                line-height: 1.3;
            }
        }
        .middle {
            margin-bottom: 2.5rem;
        }
        .middle, 
        .bottom {
            line-height: 1.3;
            margin-top: 2rem;
            .tit {
                font-weight: 500;
                font-size: 18px;
            }
            .add {
                font-size: 18px;
            }
            .phone {
                padding-bottom: 1px;
                width: fit-content;
                font-size: 18px;
                border-bottom: 1px solid #fff;
                text-decoration: none;
                color: #fff;
            }
        }
    }
    .middle {
        
        .top{
            
            .bold {
                font-size: 36px;
                font-weight: 600;
                text-decoration: underline;
            }
        }
        .middle, 
        .bottom {
            line-height: 1.3;
            margin-top: 2rem;
            font-size: 18px;
            .tit {
                font-weight: 500;
                font-size: 18px;
            }
            .add {
                font-size: 18px;
            }
            .phone {
                padding-bottom: 1px;
                width: fit-content;
                border-bottom: 1px solid #fff;
                text-decoration: none;
                color: #fff;
            }
        }
    }
    .xc {
        transform: translateY(-.9rem);
    }
    .cx {
        transform: translateY(-1rem);
    }
    .right {
        .top{
            margin-bottom: 4rem;
            img {
                width: 65%;
            }
            .bold {
                font-size: 1.7rem;
                font-weight: 500;
                text-decoration: underline;
            }
            .small {
                font-size: 18px;
            }
        }
       
        .middle, 
        .bottom {
            line-height: 1.3;
            margin-top: 2rem;
            margin-bottom: 2.5rem;

            img {
                width: 15%;
                margin-right: .7rem;
            }

            .small {
                font-size: 17px;
            }
            .tit {
                font-weight: 500;
                font-size: .9rem;
            }
            .add {
                font-size: .9rem;
            }
            .phone {
                padding-bottom: 1px;
                width: fit-content;
                border-bottom: 1px solid #fff;
            }
        } 
        .middle {
            margin-bottom: 4rem;
        }
    }
}

@media screen and (max-width: 690px) {
    .footer {
        padding: 3rem 1rem 1rem 1rem;
    }
}