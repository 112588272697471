@font-face {
  font-family: projFont;
  src: url(font/Gilroy-FREE/Gilroy-FREE/Gilroy-Light.otf);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Questrial', sans-serif;
}