.templates {
    padding: 5rem 6rem;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    .topish {
        display: flex;
        align-items: baseline;
        gap: 3rem;

        .lefti {
            flex: 1 1 700px;

            .title {
                font-size: 21px;
                line-height: 35px;
                width: 739px;
                font-weight: 500;
            }
        }
        .righti {
            flex: 0 1 400px;

            .lab {
                font-weight: 700;
                color: black;
            }
            ul {
                line-height: 26px;
                font-weight: 500;
                li {
                    list-style: none;
                }
            }
        }
    }
    .gallery {
        margin-top: 6rem;

        img,
        video {
            width: 100%;
            margin-bottom: .7rem;
        }
    }
}

@media screen and (max-width: 1100px) {
    .templates {
        padding: 3rem 1rem 2rem 1rem;

        .topish {
            // flex-direction: column;
            flex-wrap: wrap;

            .lefti {
                .title {
                    font-size: 18px;
                    width: 100%;
                    line-height: 30px;
                }
            }
            .righti {
                // margin-top: -14rem;
            }
        }
    }
}