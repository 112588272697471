#bg {
    background-color: rgba(0, 0, 0, .3) !important;
}

.navbar {
    display: flex;
    padding: 1rem;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    position: fixed;
    z-index: 12;
    width: 100%;
    transition: 250ms ease;

    img {
        cursor: pointer;
        padding-left: 10px;
    }

    .links {
        display: flex;
        transition: 250ms ease;

        li {
            list-style: none;
            color: #fff;
            margin: 0 1rem;
            position: relative;
            padding-bottom: 2px;
            margin-block: 2px;

            &::after {
                content: "";
                width: 100%;
                height: 1px;
                background-color: #fff;
                border-radius: 4px;
                position: absolute;
                left: 0;
                bottom: 0;
                transform: scaleX(0);
                transform-origin: left;
                transition: transform .25s ease;
            }

            &:hover::after {
                transform: scaleX(1);
            }
        }
        

    }
}

@media screen and (max-width: 900px) {
    .navbar {
        .links {
            display: none;
        }
    }
}